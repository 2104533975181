export default {
  data() {
    return {
      loading: {
        step1: false,
        step2: false,
        step3: false,
      },
      error: {
        step1: null,
        step2: null,
        step3: null,
        step: null,
        price: null,
      },
      info: {
        step1: null,
        step2: null,
        step3: null,
        step: null,
        price: null,
      },
      validationError: null,
      existingPolicy: false,
      existingPolicyKey: null,
    };
  },
  watch: {
    "product.step": {
      handler: function (newValue, oldValue) {
        this.scrollToTop();

        // Update temporary product if product is not existing
        if (!this.existingPolicy) {
          this.$store.commit("temporaryProduct", this.product);
        }

        // If you change step, update router with push,. otherwise just update router with replace, so that navigation is intact
        if (oldValue && newValue != oldValue) {
          // Update query with new step value
          let query = this.copy(this.$route.query);
          query.step = newValue;

          // Push value to router
          this.$router
            .push({ name: this.product.productName, query: query })
            .catch(() => {});
        } else if (!oldValue && newValue != this.$route.query.step) {
          // Update query with new step value
          let query = this.copy(this.$route.query);
          query.step = newValue;

          // Push value to router
          this.$router.replace({
            name: this.product.productName,
            query: query,
          });
        }
      },
      immediate: true,
    },
    "$route.query.step": {
      handler(value) {
        if (value) {
          this.product.step = parseInt(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /*
     * Parse data to api
     */
    parseSubmitData(product) {
      // Create Submit data object with standard baseInfo
      let submitData = {
        carObjectData: [],
        homeObjectData: [],
        personalAccidentObjectData: [],
        buildingObjectData: [],
        caravanObjectData: [],
        dogObjectData: [],
        travelObjectData: [],
        baseInfo: {},
      };

      // Loop through packages
      for (const [key, value] of Object.entries(product.packages)) {
        const pkey = key;

        // Do not parse?
        if ("parse" in value && !value.parse) {
          submitData[product.priceObject].push({ ...value, key: key });
          continue;
        }

        // Create car object for the array in submitData
        let currentObject = {
          key: key,
          coverages:
            "coveragesBase" in value
              ? this.$options.filters.renderTextInObject(
                  Array.from(value.coveragesBase),
                  product
                )
              : [],
        };

        // Run through fields and fill out data
        for (const [key, value] of Object.entries(product.fields)) {
          // Only send if the field is set to export
          if (value.export) {
            currentObject[key] = value.value;
          }

          // If base info set to true, export to baseInfo
          if (value.baseInfo) {
            submitData.baseInfo[key] = value.value;
          }
        }

        // Reset price on package
        value.price = null;

        // Set bundle to loading
        value.loading = true;

        // Run through coverages and put in car object
        if ("coverages" in value) {
          value.coverages.forEach((element) => {
            // Only send if package is included and set to export
            if (element.included && element.export) {
              // Create coverage element
              let coverage = {
                bundleCode: element.key,
                excessId: product.fields.excessId.value,
              };

              // Inject values
              if ("inject" in element) {
                for (const key in element.inject) {
                  coverage[key] = product.fields[element.inject[key]].value;
                }
              }

              // Push this coverage to car object
              currentObject.coverages.push(coverage);
            }
          });
        }

        //Run through extra coverages and put in coverages
        if (product.coverages && "coverages" in value) {
          //eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(product.coverages)) {
            // Only send if coverage is chosen
            if (value.chosen) {
              const keys = Array.isArray(value.keys) ? value.keys : value.keys[pkey];

              // Run through keys on extra coverage
              keys.forEach((element) => {
                let coverage = {
                  bundleCode: element,
                  excessId: product.fields.excessId.value,
                };

                // Inject values
                if ("inject" in value) {
                  for (const key in value.inject) {
                    coverage[key] = product.fields[value.inject[key]].value;
                  }
                }

                // Push this coverage to car object
                currentObject.coverages.push(coverage);
              });
            }
          }
        }

        // Map if priceobject is 'personalAccidentObjectData'
        if (product.priceObject == "personalAccidentObjectData") {
          currentObject.coverages = currentObject.coverages.map((cov) => {
            if ([200, 250].includes(parseInt(cov.bundleCode))) {
              cov.bundleSumInsured = parseInt(currentObject.bundleSumInsured);
            }

            if ([100].includes(parseInt(cov.bundleCode))) {
              cov.bundleSumInsured = parseInt(currentObject.deathSumInsured);
            }

            return cov;
          });
        }

        // Push to submit data
        submitData[product.priceObject].push(currentObject);

        // Add extra products
        if ("extra" in product) {
          for (const key in product.extra) {
            const extra = product.extra[key];

            if (extra.chosen) {
              let prod = { ...extra.product, key: `extra_${key}` };

              // imports from main product
              if ("import" in extra) {
                for (const imp of extra.import) {
                  prod[imp] = currentObject[imp];
                }
              }

              // Add prod
              submitData[product.priceObject].push(prod);
            }
          }
        }
      }

      return submitData;
    },
    /*
     * Function for changing step
     */
    changeStep(step) {
      this.product.step = step;
    },
    /*
     * Remove packages
     */
    resetPackages() {
      let self = this;

      // Set all packages to false
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(self.product.packages)) {
        value.value = false;
      }
    },
    /*
     * When a package is chosen
     */
    choosePackage(value) {
      this.product.package = value;

      // Reset selection
      this.resetPackages();
      value.value = true;

      // Track data for retargeting
      this.trackData("calculatePrice", this.product, this.$store.state.basket);

      // Update temporary product if product is not existing
      if (!this.existingPolicy) {
        this.$store.commit("temporaryProduct", this.product);
      }
      // If exiting policy, update existing policy
      if (this.existingPolicy && this.allRequirementsMet) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      }
    },
    /*
     * Go to message box
     */
    messageBox() {
      this.$router.push({
        name: "Henvendelse",
        params: {
          validation: this.validationError,
        },
      });
    },
    /*
     * Set from price response
     */
    insertPrice(data) {
      // Check if the response has objects
      if (data.objects && data.objects.length > 0) {
        // Run through coverages and put in car object
        data.objects.forEach((element) => {
          if ("key" in element && element.key.indexOf("extra_") == 0) {
            // Price is for extra product
            this.product.extraPrices[element.key.split("_")[1]] = element;
          } else if ("key" in element) {
            // Price is for package
            this.product.packages[element.key].price = element;

            // Remove loading
            this.product.packages[element.key].loading = false;
          } else {
            // Add object as key
            element.key = element.object.toLowerCase();

            // Price is for package
            this.product.packages[element.object.toLowerCase()].price = element;

            // Remove loading
            this.product.packages[element.object.toLowerCase()].loading = false;
          }
        });
      }
    },
    /*
     * Form error
     */
    formError(property, value) {
      this.product.fields[property].error = value;
    },
  },
};
