<template>
  <div class="container" id="packages">
    <h2 class="text-align-center margin-top-40 margin-bottom-40">{{ altTitle || "Vælg din pakke" }}</h2>
    <slot name="subtitle"></slot>
    <div class="packages">
      <template v-for="(bundle, index) in packages">
        <div
          :key="index"
          :style="_package_item_style"
          class="package-item"
          :class="[
            { chosen: bundle.value },
            { loading: bundle.loading },
            { disabled: !bundle.price || !requirementsMet },
          ]"
        >
          <span v-if="bundle.infoBox" class="sticker small">{{ bundle.infoBox.text }}</span>
          <input
            type="radio"
            :id="bundle.name"
            name="package"
            class="package-input"
            :value="bundle"
            :checked="bundle.value"
            @invalid.prevent="onInvalid"
            tabindex="-1"
            required
          />
          <div class="package-info">
            <h5 v-if="bundle.name != ''">{{ bundle.name }}</h5>
            <div class="package-price">
              <span class="number">
                <template v-if="bundle.price && bundle.price.totalMonthlyPrice && !bundle.loading">{{ numFormat(bundle.price.totalMonthlyPrice) }}</template>
                <template v-else-if="!bundle.price && !bundle.loading">.....</template>
              </span>
              <span class="description">kr./md.</span>
            </div>
          </div>
          <div class="package-coverages">
            <template v-for="(coverage, index) in bundle.coverages">
              <div :key="index" v-if="coverage.show" class="coverage" :class="{ 'included' : coverage.included }" v-on:click="showModal(coverage.value, coverage.text || coverage.value)">
                {{ coverage.value }}
              </div>
            </template>
          </div>
          <div v-if="Object.keys(packages).length > 1" class="package-buttons">
            <button type="button" class="button block-button" :class="{ 'white': !bundle.value }" v-on:click="choosePackage(bundle)" :disabled="!bundle.price || !requirementsMet">
              <template v-if="bundle.value">Valgt</template>
              <template v-else>Vælg denne pakke</template>
            </button>
          </div>
        </div>
      </template>
    </div>
    <!-- Fejlbesked -->
    <Message
      v-if="error"
      :message="error"
      type="error"
      v-on:formFunction="formFunction"
    />
    <div v-if="packageLink" class="display-block text-align-center margin-top-30">
      <a :href="packageLink" target="_blank" class="font-size-16">{{ altLinkText || "Læs mere om vores pakker" }}</a>
    </div>
  </div>
</template>

<script>
import Message from "@/components/message.vue";

export default {
  name: "Packages",
  components: {
    Message,
  },
  props: {
    packages: {
      type: Object,
      default: null,
    },
    packageLink: {
      type: String,
      default: null,
    },
    requirementsMet: {
      type: Boolean,
      default: false,
    },
    altTitle: {
      type: String,
      default: null,
    },
    altLinkText: {
      type: String,
      default: null,
    },
    altErrorMessage: {
      type: String,
    },
  },
  watch: {
    packages: {
      immediate: true,
      handler() {
        const keys = Object.keys(this.packages);

        // Autoselect if onle one
        if (keys.length == 1) {
          this.choosePackage(this.packages[keys[0]]);
        }
      },
    }
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    _package_item_style() {
      return Object.keys(this.packages).length == 1 ? {
        maxWidth: "500px",
        margin: "auto"
      } : {};
    }
  },
  methods: {
    /*
     * Event when you choose a package
     */
    choosePackage(bundle) {
      // Reset packages
      this.resetPackages();

      // Reset error
      this.error = null;

      // Set chosen package to true
      bundle.value = true;

      // Set chosen package to input
      this.$emit("packageChoice", bundle);
    },
    /*
     * Remove packages
     */
    resetPackages() {
      let self = this;

      // Set all packages to false
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(self.packages)) {
        value.value = false;
      }
    },
    /*
     * Show error on invalid
     */
    onInvalid() {
      if (this.requirementsMet) {
        if (this.altErrorMessage) {
          this.error = {
            headline: this.altErrorMessage,
          };
        } else {
          this.error = {
            headline: "Vælg en pakke, for at kunne tilføje forsikringen til din indkøbskurv.",
          };
        }
        this.scrollTo("packages");
      }
    },
  },
};
</script>
