<template>
  <form v-if="policy" id="bmcLeasing" v-on:submit.prevent="updatePolicy">
    <div class="container small margin-bottom-30">
      <div class="logo-image">
        <img
          src="@/assets/img/logo/logo-kvadrat-lilla.svg"
          alt="Aros Forsikring GS"
          width="100"
          height="93"
        />
      </div>
      <h1 class="page-headline margin-bottom-40">{{ product.productName }}</h1>
      <p class="text-align-center font-size-18">
        <b>Tillykke med din nye leasingbil fra BMC Leasing.</b><br /><br />Her på siden
        kan du udvide din obligatoriske ansvars- og kaskoforsikring med
        autohjælp og udvidet glasskadeforsikring. Skulle du have brug for at
        tale med en af vores forsikringsrådgivere, eller vil du gerne have et
        tilbud på dine øvrige forsikringer, er du altid velkommen til at
        kontakte os.<br /><br />
        Du kan ringe til os på <a href="tel:7060 2402">7060 2402</a> – vi har ingen telefonkø.
      </p>
    </div>

    <!-- Information om bil -->
    <div class="container small" v-if="product.fields.registrationNumber.value">
      <div class="vehicle-information border-radius-lg margin-top-0">
        <i class="fa-light fa-fw fa-car-side vehicle-icon"></i>
        <div class="vehicle-information-text">
          <span class="registration-number">Oplysninger for <b>"{{ product.fields.registrationNumber.value }}"</b></span>
          <table class="vehicle-information-table">
            <tr>
              <td>Mærke</td>
              <td>{{ product.fields.vehicleBrand.value }}</td>
            </tr>
            <tr>
              <td>Model</td>
              <td>{{ product.fields.vehicleModel.value }}</td>
            </tr>
            <tr>
              <td>Årgang</td>
              <td>{{ product.fields.modelYear.value }}</td>
            </tr>
            <tr>
              <td>Variant</td>
              <td>{{ product.fields.vehicleVariant.value }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!-- Pakkekomponent -->
    <packages
      altLinkText="Læs mere om vores bilforsikring"
      altErrorMessage="Vælg en pakke, for at kunne opdatere din forsikring."
      :packages="product.packages"
      :packageLink="product.packageLink"
      :requirementsMet="allRequirementsMet"
      v-on:packageChoice="choosePackage"
      class="margin-top-60"
    >
      <template #subtitle v-if="product.fields.excessId.selected">
        <p class="text-align-center margin-bottom-30 margin-top-negative-10 font-size-18">Selvrisiko: {{ product.fields.excessId.selected }}</p>
      </template>
    </packages>

    <!-- Extra packages -->
    <coverages
      v-model="product.coverages"
      :requirementsMet="allRequirementsMet"
      ref="coverages"
      v-on:packageChoice="choosePackage"
    />

    <!-- Betaling -->
    <div class="container small">
      <h2 class="text-align-center margin-top-60 margin-bottom-40">
        Betalingsoplysninger
      </h2>
      <div v-if="!policy.hasPayment" class="box">
        <template v-if="!policy.hasPayment">
          <div class="margin-bottom-30">
            <p>
              Vi tilmelder dig automatisk PBS med månedlig betaling. Vær opmærksom
              på, at du her i den første opkrævning både skal betale for den
              igangværende måned og den følgende måned.
            </p>
          </div>
          <!-- Reg. nr. -->
          <FormInput
            :data="product.fields.regnr"
            :product="product"
            v-on:formFunction="formFunction"
          />

          <!-- Reg. nr. -->
          <FormInput
            :data="product.fields.accountNumber"
            :product="product"
            v-on:formFunction="formFunction"
          />
        </template>
      </div>
      <Message
        v-else
        :message="{
          headline: 'Du er allerede tilmeldt Betalingsservice',
          text: 'Da du allerede er kunde i Aros Forsikring tilføjes betalingen af denne forsikring til din eksisterende Betalingsserviceaftale med Aros Forsikring.',
        }"
        type="info"
      />
    </div>

    <!-- Nyhedsbrev -->
    <div class="container small">
      <h2 class="text-align-center margin-top-60 margin-bottom-40">
        Nyhedsbrev og markedsføring
      </h2>
      <div class="box">
        <h5>Tilmeld dig vores nyhedsbrev og produktnyheder</h5>
        <div class="display-block margin-bottom-20">
          <p>
            Modtag forebyggende råd og viden om dine kundefordele med vores
            nyhedsbrev, og få direkte information om vores produktnyheder, når
            du tilmelder dig vores markedsføring. Du vil modtage nyhedsbreve 1-2
            gange om måneden.
          </p>
          <p>
            Du kan til enhver tid tilbagekalde dine samtykker direkte i e-mailen
            eller via vores hjemmeside.
          </p>
        </div>

        <!-- Nyhedsbrev -->
        <FormInput
          :data="product.fields.news"
          :product="product"
          v-on:formFunction="formFunction"
          class="margin-bottom-20"
        />

        <!-- Markedsføring -->
        <FormInput
          :data="product.fields.marketing"
          :product="product"
          v-on:formFunction="formFunction"
        />
      </div>
    </div>

    <div class="container small margin-top-40">
      <div class="button-flex-container justify-content-center">
        <button type="submit" class="button large-padding" form="bmcLeasing">
          Opdater forsikring
        </button>
      </div>
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :altTitle="`Mere om ${product.productName}`"
      :productId="product.productId"
      :productLink="product.productLink"
      :showContactButton="false"
      v-on:contactFromFooter="messageBox"
    >
      Med vores bilforsikring er din leasede bil forsvarligt dækket ind i en
      travl hverdag, hvor meget kan ske – også uheld og ulykker. Du vælger selv,
      hvilke tilvalgsforsikringer du ønsker at tilføje til din bilforsikring. Er
      du uheldig og får en skade, kan du frit vælge dit lokale værksted, og
      vælger du et af vores fordelsværksteder, giver det mulighed for lånebil på
      attraktive vilkår.
    </product-footer>
  </form>
</template>
<script>
import M90 from "@/assets/json/M90.json";
import FormInput from "@/components/FormInput.vue";
import productFooter from "@/components/product-footer.vue";
import debounce from "lodash/debounce";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";
import packages from "@/components/packages.vue";
import coverages from "@/components/coverages.vue";
import Message from "@/components/message.vue";

export default {
  name: "BmcLeasing",
  components: {
    productFooter,
    packages,
    coverages,
    FormInput,
    Message
  },
  data() {
    return {
      productId: "M90",
      product: this.copy(M90),
      token: null,
      policy: null,
    };
  },
  mixins: [commonProduct, validateData],
  mounted() {
    this.getBmcToken();
  },
  computed: {
    /**
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.bmcPrice) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },

    /**
     * Id from route
     */
    routeId() {
      return this.$route.query.id;
    },
  },
  watch: {
    "product.fields.excessId": {
      handler() {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler() {
        this.calculateEveryPrice();
      },
      deep: true,
    },

    /**
     * Get policy data when token is filled out
     */
    token: {
      handler() {
        this.getPolicyData();
      },
    },

    /**
     * When policy data is filled out, map
     */
    policy: {
      handler(data) {
        this.mapBmcData(data);
      },
    },

    /**
     * Set excess
     */
    "policy.excess": {
      handler(excess) {
        this.setExcess(excess);
      },
    },

    /**
     * Check if nedsat risiko is chosen
     */
    "product.coverages.down.chosen": {
      handler(chosen) {
        this.chooseExcess(chosen);
      },
    },
  },
  methods: {
    /**
     * Get token for BMC
     */
    async getBmcToken() {
      if (!this.routeId) {
        this.$router.push({ name: "BmcLeasingError" });
      }

      this.$store.commit("loadingTrue");

      const apiString = "/api/bmc/token";

      console.log({
        id: this.routeId,
      });

      // Create encrypted data
      const apiData = JSON.stringify(
        this.encrypt(
          JSON.stringify({
            id: this.routeId,
          })
        )
      );

      this.axios({
        method: "post",
        url: apiString,
        headers: {
          "Content-Type": "application/json",
          "x-ws-key": process.env.VUE_APP_KEY,
          Authorization: `Bearer ${this.$store.state.token}`,
        },
        data: apiData,
      })
        .then((response) => {
          this.token = response.data.token;

          // Set standard package
          this.choosePackage(this.product.packages.udvidet);
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.reason != null) {
            this.$router.push({
              name: "Error",
              query: {
                p: "M90",
                r: error.response.data.reason,
              },
            });
          } else {
            this.$router.push({name: "Error"});
          }
          this.$store.commit("loadingFalse");
        });
    },

    /**
     * Get token for BMC
     */
    async getPolicyData() {
      const apiString = "/api/bmc/policy/get";

      // Create encrypted data
      const apiData = JSON.stringify(
        this.encrypt(
          JSON.stringify({
            id: this.routeId,
          })
        )
      );

      this.axios({
        method: "post",
        url: apiString,
        headers: {
          "Content-Type": "application/json",
          "x-ws-key": process.env.VUE_APP_KEY,
          "x-ws-bmc": this.token,
          Authorization: `Bearer ${this.$store.state.token}`,
        },
        data: apiData,
      })
        .then((response) => {
          this.policy = response.data;
        })
        .catch(() => {
          this.$router.push({
            name: "Error",
            params: {
              headline: "Øv!",
              body: `<p>Der er desværre sket en fejl. Du bedes derfor kontakte os på tlf. <a href="tel:70602402">7060 2402</a>.</p>`,
            },
          });
          this.$store.commit("loadingFalse");
        });
    },

    /**
     * Map data from BMC
     */
    mapBmcData(data) {
      if (data.age) {
        this.product.fields.age.value = data.age;
        this.product.fields.zipCode.value = data.zipCode;
      }

      if (data.carObject) {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(data.carObject)) {
          if (this.product.fields[key]) {
            this.product.fields[key].value = value;
          }
        }
      }

      // Set loading to false on rerender
      this.$nextTick(() => {
        this.$store.commit("loadingFalse");
      })
    },

    /**
     * Set excess id based on age
     */
     setExcess(excess) {
      this.product.fields.excessId.value = excess.value;
      this.product.fields.excessId.selected = excess.label;
    },

    /**
     * Set excess id based on age
     */
    setExcessFromAge(age) {
      if (age < 25) {
        this.product.fields.excessId.value = "3";
        this.product.fields.excessId.selected = "7.500 kr.";

        // Remove nedsat selvrisiko
        //delete this.product.coverages.down;

        let coverages = this.$refs.coverages;
        if (coverages) {
          coverages.handleChange();
        }
        return;
      }
      this.product.fields.excessId.value = "1";
      this.product.fields.excessId.selected = "5.000 kr.";
    },

    /**
     * When you chooses nedsat risiko excess should change
     */
    chooseExcess(chosen) {
      if (this.product.fields.age.value < 25) {
        return;
      }
      if (chosen) {
        this.product.fields.excessId.value = "2";
        this.product.fields.excessId.selected = "3.500 kr.";
      } else {
        this.product.fields.excessId.value = "1";
        this.product.fields.excessId.selected = "5.000 kr.";
      }
    },

    parseBmcData(product) {
      const submitData = {
        age: product.fields.age.value,
        zipCode: product.fields.zipCode.value,
        id: this.routeId,
        priceData: [],
      };

      // Loop through packages
      for (const [key, value] of Object.entries(product.packages)) {
        // Create car object for the array in submitData
        let currentObject = {
          key: key,
          coverages: [],
        };

        // Reset price on package
        value.price = null;

        // Set bundle to loading
        value.loading = true;

        // Run through coverages and put in car object
        if ("coverages" in value) {
          value.coverages.forEach((element) => {
            // Only send if package is included and set to export
            if (element.included && element.export) {
              // Create coverage element
              let coverage = {
                bundleCode: element.key,
                excessId: product.fields.excessId.value,
              };

              // Push this coverage to car object
              currentObject.coverages.push(coverage);
            }
          });
        }

        //Run through extra coverages and put in coverages
        if (product.coverages && "coverages" in value) {
          //eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(product.coverages)) {
            // Only send if coverage is chosen
            if (value.chosen) {
              // Run through keys on extra coverage
              value.keys.forEach((element) => {
                let coverage = {
                  bundleCode: element,
                  excessId: product.fields.excessId.value,
                };

                // Inject values
                if ("inject" in value) {
                  for (const key in value.inject) {
                    coverage[key] = product.fields[value.inject[key]].value;
                  }
                }

                // Push this coverage to car object
                currentObject.coverages.push(coverage);
              });
            }
          }
        }

        // Push to submit data
        submitData[product.priceObject].push(currentObject);
      }

      return submitData;
    },

    /**
     * Parse Bmc data for order
     */
    parseBmcOrderData(product) {
      const submitData = {
        payment: {
          registrationNumber: product.fields.regnr.value,
          accountNumber: product.fields.accountNumber.value,
        },
        emailPreference: {
          news: product.fields.news.value,
          marketing: product.fields.marketing.value,
        },
        id: this.routeId,
        description: "Test",
        orderData: {
          coverages: [],
        },
      };

      // Run through coverages and put in car object
      if (product.package) {
        product.package.coverages.forEach((element) => {
          // Only send if package is included and set to export
          if (element.included && element.export) {
            // Create coverage element
            let coverage = {
              bundleCode: element.key,
              excessId: product.fields.excessId.value,
            };

            // Push this coverage to car object
            submitData.orderData.coverages.push(coverage);
          }
        });
      }

      //Run through extra coverages and put in coverages
      if (product.coverages) {
        //eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(product.coverages)) {
          // Only send if coverage is chosen
          if (value.chosen) {
            // Run through keys on extra coverage
            value.keys.forEach((element) => {
              let coverage = {
                bundleCode: element,
                excessId: product.fields.excessId.value,
              };

              // Push this coverage to car object
              submitData.orderData.coverages.push(coverage);
            });
          }
        }
      }

      return submitData;
    },

    /**
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Get submit data without coverages
      let submitData = self.parseBmcData(self.product);

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/bmc/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            "x-ws-bmc": this.token,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then(function (response) {
          self.insertPrice(response.data);
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");
        })
        .catch(function () {
          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),

    /**
     * Function for updating policy
     */
    async updatePolicy() {
      // If all requirements are not met, break from function
      if (!this.allRequirementsMet) return;

      // Get submit data without coverages
      let submitData = this.parseBmcOrderData(this.product);

      // Console log if in dev, test or beta environment
      this.clog(submitData);

      // Create encrypted data
      let encryptedData = JSON.stringify(
        this.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/bmc/policy`;

      this.axios({
        method: "put",
        url: apiString,
        headers: {
          "Content-Type": "application/json",
          "x-ws-key": process.env.VUE_APP_KEY,
          "x-ws-bmc": this.token,
          Authorization: `Bearer ${this.$store.state.token}`,
        },
        data: encryptedData,
      })
        .then((response) => {
          this.clog(response);
          this.$router.push({
            name: "BmcLeasingEnd",
            params: {
              token: this.token,
            },
          });
        })
        .catch((error) => {
          this.cerror(error);
          this.$router.push({
            name: "Error",
            params: {
              headline: "Øv!",
              body: `<p>Der er desværre sket en fejl. Du bedes derfor kontakte os på tlf. <a href="tel:70602402">7060 2402</a>.</p>`,
            },
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-image {
  max-width: 100px;
  margin: 0 auto 4rem;
}

[v-cloak] {
    display: none !important;
}
</style>
