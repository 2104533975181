export default {
  methods: {
    /*
     * Validate data based on input
     */
    validateData(data, step) {
      // Parse validation data
      let parsedData = this.parsevalidationData(data, step);

      // Console log if in dev, test or beta environment
      this.clog("data for validation", parsedData);

      // Create encrypted data
      let encryptedData = JSON.stringify(
        this.encrypt(JSON.stringify(parsedData))
      );

      // Create api string
      let apiString = `/api/validate`;

      return this.axios({
        method: "post",
        url: apiString,
        headers: {
          "Content-Type": "application/json",
          "x-ws-key": process.env.VUE_APP_KEY,
          Authorization: `Bearer ${this.$store.state.token}`,
        },
        data: encryptedData,
      }).then((response) => {
        return response.data;
      });
    },
    /*
     * Parse validation fields from product
     */
    parsevalidationData(data, step) {
      // Parsed data to return
      let parsedData = new Object();

      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(data.fields)) {
        if (value.validation && (!value.step || value.step == step || ("extraValidationSteps" in value && value.extraValidationSteps.includes(step)))) {
          parsedData[value.property] = value.filter
            ? this.$options.filters[value.filter](value.value)
            : value.value;
        }
      }

      // Return parsed data
      return parsedData;
    },
  },
};
